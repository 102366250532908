import styled from "styled-components";
import { Collapse } from "react-collapse/lib/Collapse";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { LightText } from "./index";

function Burger( props ) {
	const {
		isOpened,
		setIsOpened,
		items,
		className
	} = props;

	const navigate = useNavigate();

	const [ opened, setOpened ] = useState( [
		false,
		false,
		false
	] );

	const goToPage = ( href ) => {
		setIsOpened( false );
		navigate( href );
	}

	return (
		<BurgerBlock className={ `${ className ? className : '' }` } style={ {display: isOpened ? 'flex' : 'none'} } onClick={(event) => event.stopPropagation()}>
			<div className="flex flex-col gap-4 w-full mb-12">
				<div className="flex justify-end">
					<BurgerCloseButton
						onClick={ () => setIsOpened( false ) }
					>
						<img src="/static/svg/x-lg.svg" alt="x" />
					</BurgerCloseButton>
				</div>
				{
					items.map( item => {
						const index = items.indexOf( item );
						return item.children && item.children.length
							? (
								<div
									key={ `burger-item-${ index }` }
									className='cursor-pointer flex flex-col'
									onClick={ () => {
										const newOpened = [ ...opened ];
										newOpened[ index ] = !newOpened[ index ];
										setOpened( newOpened );
									} }
								>
									<LightText className="flex justify-between w-full font-bold">
										{ item.displayName }
										<LightText>{ opened[ index ] ? "▲" : "▼" }</LightText>
									</LightText>

									<Collapse isOpened={ opened[ index ] }>
										<div className="flex flex-col gap-4 pl-4 pt-4">
											{
												item.children.map( child =>
													child.href.startsWith("/static")
														?
														<a
															key={ `burger-collapse-item-${ item.children.indexOf( child ) }` }															href={ child.href }
															onClick={event => event.stopPropagation()}
															target="_blank"
															rel="noreferrer"
														>
															<LightText>{ child.displayName }</LightText>
														</a>
														: <LightText
															key={ `burger-collapse-item-${ item.children.indexOf( child ) }` }
															className='cursor-pointer'
															onClick={ (event) => {
																event.stopPropagation();
																goToPage( child.href );
															} }
														>
															{ child.displayName }
														</LightText>
												)
											}
										</div>
									</Collapse>

								</div>
							)
							: (
								<LightText
									key={ `burger-item-${ items.indexOf( item ) }` }
									className='cursor-pointer font-bold'
									onClick={ () => goToPage( item.href ) }
								>
									{ item.displayName }
								</LightText>
							)
					} )
				}
			</div>
		</BurgerBlock>
	);
}

export default Burger;

export const BurgerTrigger = ( {className, setIsOpened} ) => {
	return (
		<div className={ className } onClick={ () => setIsOpened( true ) }>
			<BurgerButton>
				<BurgerButtonLine/>
				<BurgerButtonLine/>
				<BurgerButtonLine/>
			</BurgerButton>
		</div>
	);
}

const BurgerBlock = styled.div`
	background-color: rgb(0,0,0, 0.8);
	padding: 1rem;
	backdrop-filter: blur(4px);
	width: 100%;
	height: 100%;
	position: fixed;
	overflow: scroll;
	top: 0;
	flex-direction: column;
	z-index: 20;
	overscroll-behavior: contain;
`;

const BurgerButton = styled.button`
	width: 60px;
	height: 60px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	&:hover{
		filter: brightness(75%);
	}
`;

const BurgerButtonLine = styled.span`
	background: #f3f3f3;
	width: 100%;
	height: 4px;
`;


const BurgerCloseButton = styled.button`
	width: 60px;
	height: 60px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	&:hover{
		filter: brightness(75%);
	}
`;
export const news = [
	{
		date: "25.03.2025",
		preview: "/static/img/news/250325/00.jpg",
		title: "Симпозиум «Актуальные вопросы организации практической работы среднего и младшего медицинского персонала судебно-медицинской экспертной службы»",
		body: "21 марта 2025 года в Москве, в соответствии с планом научно-практических мероприятий Российского центра судебно-медицинской экспертизы Минздрава России, состоялся Симпозиум «Актуальные вопросы организации практической работы среднего и младшего медицинского персонала судебно-медицинской экспертной службы».\n" +
			"В Симпозиуме, проведенном в очном формате, приняли участие 98 ведущих специалистов по вопросам организации сестринского дела, представителей среднего медицинского персонала судебно-экспертных организаций с многолетним опытом практической работы в экспертных подразделениях из 35 регионов России. Среди участников присутствовали и сотрудники ГКУЗ ЛО БСМЭ.",
		images: [
			"/static/img/news/250325/00.jpg",
			"/static/img/news/250325/01.jpg",
			"/static/img/news/250325/02.jpg",
			"/static/img/news/250325/03.jpg",
		],
		link: "250325"
	},
	{
		date: "20.03.2025",
		preview: "/static/img/news/200325/01.jpg",
		title: "Плановая учебная тренировка по вопросам противопожарной защиты",
		body: "С начала года в ГКУЗ ЛО БСМЭ проводятся плановые учебные тренировки по вопросам противопожарной защиты. Тренировки проводятся в подразделениях на ул. Шкапина, д. 36-38-40, лит. Б, а так же на ул. Ждановская, д. 43 и др. В ходе учебных тренировок отрабатываются знания и умения работников в условиях возникновения пожара.",
		images: [
			"/static/img/news/200325/00.jpg",
			"/static/img/news/200325/01.jpg",
			"/static/img/news/200325/02.jpg",
			"/static/img/news/200325/03.jpg",
		],
		link: "200325"
	},
	{
		date: "15.03.2025",
		preview: "/static/img/news/150325/preview.jpg",
		title: "День поисковика Ленинградскй области",
		body: "15 марта в Ломоносовском районе на базе МБУ «Районный центр культуры и молодежных инициатив» состоялось собрание поисковиков Санкт-Петербурга и Ленинградской области, в котором принимал участие сотрудник ГКУЗ ЛО БСМЭ -- заведующий отделом дежурной группы Никитин А. В.",
		images: [
			"/static/img/news/150325/00.jpg",
			"/static/img/news/150325/01.jpg",
			"/static/img/news/150325/02.jpg",
			"/static/img/news/150325/03.jpg",
			"/static/img/news/150325/04.jpg",
		],
		link: "150325"
	},
	{
		date: "14.03.2025",
		preview: "/static/img/news/140325/preview.png",
		title: "Научно-практическая конференция «Судебно-медицинская экспертиза массовых захоронений большой давности»",
		body: "13-14 марта в Великом Новгороде состоялась научно-практическая конференция «Судебно-медицинская экспертиза массовых захоронений большой давности». В конференции приняли участие сотрудники ГКУЗ ЛО БСМЭ.",
		images: [
			"/static/img/news/140325/00.webp",
			"/static/img/news/140325/01.webp",
			"/static/img/news/140325/02.webp",
			"/static/img/news/140325/03.webp",
			"/static/img/news/140325/04.webp",
			"/static/img/news/140325/05.webp",
			"/static/img/news/140325/06.webp",
		],
		link: "140325"
	},
	{
		date: "13.01.2025",
		preview: "/static/img/news/130125/02.jpg",
		title: "Актовый день, посвященный 127-летию ПСПбГМУ им. И.П. Павлова",
		body: "13 января 2025 г. сотрудники ГКУЗ ЛО БСМЭ приняли участие в Актовом дне, посвященном 127-летию ПСПбГМУ им. И.П. Павлова, который прошел в Большом зале Санкт-Петербургской академической филармонии им. Д.Д. Шостаковича. В рамках мероприятия состоялось вручение мантий и знаков Почетного доктора ПСПбГМУ им. И.П.Павлова и медалей. Актовую речь «Вклад Ленинградской (Петербургской) судебно-медицинской школы в развитие отечественной и мировой судебной медицины» прочитал врач судебно-медицинский эксперт высшей категории организационно-методического отдела ГКУЗ ЛО БСМЭ, заведующий кафедрой судебной медицины и правоведения доктор медицинских наук, профессор, Заслуженный деятель науки РСФСР Вячеслав Леонидович Попов.",
		images: [
			"/static/img/news/130125/02.jpg",
			"/static/img/news/130125/00.jpg",
			"/static/img/news/130125/01.jpg",
		],
		link: "130125"
	},
]
import { BackgroundBlock } from "../../components/ui";
import { news } from "../../data/news";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function NewsPage() {
	const title = "Новости";

	const navigate = useNavigate();

	return (
		<div className="flex flex-col gap-4 md:gap-8 ">
			<BackgroundBlock title={ title }/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8` }>
				{
					news.map(el =>
						<div
							key={ `news-item-${ news.indexOf(el) }` }
							className="flex flex-col gap-2 cursor-pointer hover:brightness-75"
							onClick={ () => navigate(el.link) }
						>
							{
								el.date &&
								<span
									className="text-sm"
								>
								{ el.date }
							</span>
							}
							<NewPreviewImage
								src={ el.preview }
								alt={ el.link }
							/>
							<div
								key={ `events-item-${ news.indexOf(el) }` }
								className="leading-5 cursor-pointer font-bold hover:underline"

							>
								{ el.title }
							</div>

						</div>
					)
				}
			</div>
		</div>
	);
}

export default NewsPage;

const NewPreviewImage = styled.img`
	height: 300px;
	width: 100%;
	object-position: center;
    object-fit: cover;
`;
import { BackgroundBlock } from "../components/ui";

function JobsPage() {
	const title = "Вакансии";
	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title } />
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<div className="flex flex-col gap-4 leading-6">
					Требуются:
					<div>• Заместитель начальника по патологоанатомической работе;</div>
					<div>• Врач — судебно-медицинский эксперт;</div>
					<div>• Врач — патологоанатом;</div>
					<div>• Медицинский регистратор;</div>
					<div>• Лаборант (с сертификатом «судебно-медицинская экспертиза» или «гистология»);</div>
					<div>• Санитар;</div>
					<div>• Водитель автомобиля;</div>
					<div>• Подсобный рабочий;</div>
					<div>• Бухгалтер;</div>
					<div>• Экономист;</div>
					<div>• Специалист по государственным закупкам (44-ФЗ);</div>
					<div>• Электромонтер;</div>
					<div>• Рабочий по комплексному обслуживанию и ремонту зданий;</div>
					<div>• Уборщик помещений;</div>
				</div>
				<br/>
				<div>
					Телефон для справок: <a className='font-bold hover:underline'
					                        href={ `tel:88122512600` }>
					8 (812) 251-26-00
				</a>
				</div>
				<div>
					E-mail: <a className='font-bold hover:underline'
					           href={ `mailto:glkadr@lo-bsme.ru` }>
					glkadr@lo-bsme.ru
				</a>
				</div>
				<div className="font-bold text-lg">
					Контактное лицо — Нефёдова Елена Владимировна
				</div>
				<br/>
				<span>Вакансии на <a
					className='font-bold underline hover:no-underline'
					href='https://spb.hh.ru/employer/3363090?hhtmFrom=vacancy'
					target='_blank'
					rel="noreferrer"
				>spb.hh.ru</a></span>
				<br/>
				<div className="flex flex-col gap-4">
					<span className="font-bold leading-6">Для медицинских работников осуществляются меры социальной поддержки:</span>
					<span>• Программа «Земский доктор»</span>
					<span>• Выплаты молодым специалистам</span>
					<span>• Компенсация расходов за аренду (найм) жилого помещения</span>
				</div>
			</div>
		</div>
	);
}

export default JobsPage;
import { BackgroundBlock } from "../../components/ui";

function UnionPage() {
	const title = "Профсоюз бюро";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<div className="flex w-full justify-center items-center">
					<a
						className="hover:brightness-90"
						href='http://przspb.ru'
						target='_blank'
						rel="noreferrer"
					>
						<img src='/static/img/union.jpg' alt='union-logo' height={ 120 }/>
					</a>
				</div>
				<div className="leading-6">
					В Бюро судебно-медицинской экспертизы Ленинградской области имеется постоянно действующая первичная
					профсоюзная организация, в которой состоят 34 человека.
				</div>
				<div className="leading-6">
					Председатель первичной организации профсоюза — Антонов Евгений Юрьевич, заведующий отделом районных
					подразделений №1.
					<div>Телефон: <a className='font-bold hover:underline'
					                 href={ `tel:88122515876` }>
						8 (812) 251-58-76
					</a>
					</div>
				</div>

				<div className="leading-6">

					Заместитель председателя — Дежинова Татьяна Авенировна, к.б.н, судебный эксперт судебно-химического
					отделения
				</div>
				<div className="leading-6">

					В профсоюзной организации состоит 34 человека.
				</div>
				<div className="leading-6">

					При активной работе профсоюзной организации в Бюро заключен Коллективный договор на 2018-2024 г.г.
				</div>
				<div className="leading-6">

					Через систему социального партнерства профсоюзному комитету удается регулировать многие
					социально-трудовые отношения в учреждении.
				</div>
				<div className="leading-6">

					Территориальная СПб и ЛО организации профсоюза работников здравоохранения РФ — <a
					className='font-bold underline hover:no-underline'
					href='http://przspb.ru'
					target='_blank'
					rel="noreferrer"
				>przspb.ru</a>
				</div>
			</div>
		</div>
	);
}

export default UnionPage;
import { Route, Routes } from "react-router-dom";
import {
    AdministrationPage,
    ConnectPage,
    ContactsPage,
    AupDepartmentsPage,
    DepartmentUniversalPage,
    DoctorsPage,
    EventsPage,
    ExamplesPage,
    HonoredDoctorsPage,
    JobsPage,
    MainPage,
    OrgansPage,
    StructurePage,
    UnionPage,
    NewsPage,
    NewsUniversalPage, AntiCorruptionPage
} from "./pages";
import { aupDepartments } from "./pages/contacts/aup-departments-page";
import MedDepartmentsPage, { medDepartments } from "./pages/contacts/med-departments-page";
import SmoDepartmentsPage, { smoDepartments } from "./pages/contacts/smo-departments-page";
import { news } from "./data/news";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/meropriyatiya" element={ <EventsPage/> }/>

            {
                news.map(item => {
                    const {
                        date,
                        title,
                        body,
                        preview,
                        images,
                        link
                    } = item;
                    return (
                        <Route key={ link } path={ `/news/${ link }` } element={
                            <NewsUniversalPage
                                key={ link }
                                date={ date }
                                title={ title }
                                body={ body }
                                preview={ preview }
                                images={ images }
                                link={ link }
                            />
                        }/>
                    )
                })
            }
            <Route exact path="/news" element={ <NewsPage/> }/>
            <Route path="/struktura" element={ <StructurePage/> }/>
            <Route path="/vrachi" element={ <DoctorsPage/> }/>
            <Route path="/zasluzhennie-vrachi" element={ <HonoredDoctorsPage/> }/>
            <Route path="/profsoyuz-buro" element={ <UnionPage/> }/>
            <Route path="/kontroliruyushie-organi" element={ <OrgansPage/> }/>

            <Route path="/vakansii" element={ <JobsPage/> }/>

            <Route path="/kontakti" element={ <ContactsPage/> }/>
            <Route path="/obratnaya-svyaz" element={ <ConnectPage/> }/>
            <Route path="/administrativno-upravvlencheskij-personal" element={ <AupDepartmentsPage/> }/>
            <Route path="/sudebno-mediczinskie-otdely" element={ <SmoDepartmentsPage/> }/>
            <Route path="/otdel-issledovaniya-veshhestvennykh-dokazatelstv-i-biologicheskikh-obektov"
                   element={ <MedDepartmentsPage/> }/>
            <Route path="/administraciya" element={ <AdministrationPage/> }/>
            {
                aupDepartments.slice(1).map(department => {
                    const {
                        href,
                        title,
                        headPost,
                        headName,
                        headPhone,
                        headDisplayedPhone,
                        secondPhone,
                        secondDisplayedPhone,
                        secondPhoneName,
                        operatingMode,
                        body
                    } = department;
                    return (
                        <Route key={ href } path={ href } element={
                            <DepartmentUniversalPage
                                key={ href }
                                title={ title }
                                headPost={ headPost }
                                headName={ headName }
                                headPhone={ headPhone }
                                headDisplayedPhone={ headDisplayedPhone }
                                secondPhone={ secondPhone }
                                secondDisplayedPhone={ secondDisplayedPhone }
                                secondPhoneName={ secondPhoneName }
                                operatingMode={ operatingMode }
                                body={ body }
                                isAUP={ true }
                            />
                        }/>
                    )
                })
            }
            {
                smoDepartments.slice(1, 3).map(department => {
                    const {
                        href,
                        title,
                        headPost,
                        headName,
                        headPhone,
                        headDisplayedPhone,
                        secondPhone,
                        secondDisplayedPhone,
                        secondPhoneName,
                        operatingMode,
                        body
                    } = department;
                    return (
                        <Route key={ href } path={ href } element={
                            <DepartmentUniversalPage
                                key={ href }
                                title={ title }
                                headPost={ headPost }
                                headName={ headName }
                                headPhone={ headPhone }
                                headDisplayedPhone={ headDisplayedPhone }
                                secondPhone={ secondPhone }
                                secondDisplayedPhone={ secondDisplayedPhone }
                                secondPhoneName={ secondPhoneName }
                                operatingMode={ operatingMode }
                                body={ body }
                                isSMO={ true }
                            />
                        }/>
                    )
                })
            }
            {
                medDepartments.map(department => {
                    const {
                        href,
                        title,
                        headPost,
                        headName,
                        headPhone,
                        headDisplayedPhone,
                        secondPhone,
                        secondDisplayedPhone,
                        secondPhoneName,
                        operatingMode,
                        body
                    } = department;
                    return (
                        <Route key={ href } path={ href } element={
                            <DepartmentUniversalPage
                                key={ href }
                                title={ title }
                                headPost={ headPost }
                                headName={ headName }
                                headPhone={ headPhone }
                                headDisplayedPhone={ headDisplayedPhone }
                                secondPhone={ secondPhone }
                                secondDisplayedPhone={ secondDisplayedPhone }
                                secondPhoneName={ secondPhoneName }
                                operatingMode={ operatingMode }
                                body={ body }
                            />
                        }/>
                    )
                })
            }

            {/*<Route path="/normativnie-akti" element={ <ActsPage/> }/>*/ }
            <Route path="/obrazci-zaprosov" element={ <ExamplesPage/> }/>
            <Route path="/dokumenty-po-protivodeystviyu-korrupcii" element={ <AntiCorruptionPage/> }/>

            <Route exact path="/" element={ <MainPage/> }/>
        </Routes>
    );
}
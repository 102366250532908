import { StyledH1 } from "../components/ui";
import styled from "styled-components";
import { BlockWithBackgroundColor } from "../components/ui/background-block";

function MainPage() {
	const title = "Государственное казенное учреждение здравоохранения Ленинградской области\nБюро судебно-медицинской экспертизы";
	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BlockWithBackgroundColor>
				<StyledH1
					className={ `font-bold text-xl md:text-3xl text-center ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-8 lg:py-4' }` }>{ title }</StyledH1>
			</BlockWithBackgroundColor>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }>
				<div className="flex justify-center items-center w-full">
					<img src="/static/img/background.jpg" alt="main-page-img" height={ 600 }/>
				</div>
				<div className="flex flex-col gap-4">
					<div className="leading-6">
						Государственное казенное учреждение здравоохранения Ленинградской области Бюро
						судебно-медицинской
						экспертизы (далее — Учреждение) создано в соответствии с приказом Ленинградского Областного
						Отдела
						Здравоохранения от 13 ноября 1952 года № 573. Наименование при создании – «Областное бюро
						судебно-медицинской экспертизы Ленинградской области».
					</div>
					<div className="leading-6">
						Учреждение создано путем изменения типа Государственного бюджетного учреждения здравоохранения
						Ленинградской области Бюро судебно-медицинской экспертизы в соответствии с постановлением
						Правительства Ленинградской области от 30 ноября 2010 года № 324 «О создании казенных учреждений
						путем изменения типа существующих бюджетных учреждений Ленинградской области».
					</div>
					<div className="leading-6">
						Учреждение реорганизовано путем присоединения к нему Государственного казенного учреждения
						здравоохранения Ленинградской области «Патолого-анатомическое бюро Комитета по здравоохранению
						Ленинградской области» в соответствии с распоряжением Правительства Ленинградской области от 17
						июля
						2018 года № 398-р «О реорганизации Государственного казенного учреждения здравоохранения
						Ленинградской области Бюро судебно-медицинской экспертизы».
					</div>
					<div className="leading-6">
						Учреждение является правопреемником Государственного казенного учреждения здравоохранения
						Ленинградской области «Патолого-анатомическое бюро Комитета по здравоохранению Ленинградской
						области».
					</div>
				</div>

				<div className="font-bold text-2xl text-center w-full">
					Важное
				</div>
				<div className="flex flex-col gap-12">
					<div className="leading-6">
						В Ленинградской области проходит прогармма «Герои команды 47», целью которой является
						профподготовка руководителей-ветеранов СВО для последующей работы в органах государственной
						власти. Регистрация на участие в конкурсном отборе осуществляется в период с
						23 февраля 2025 года по 27 марта 2025 года. Участниками могут быть граждане Российской
						Федерации,
						имеющие высшее образование, являющиеся участниками или ветеранами СВО,
						проживающие в Ленинградской области. Подать заявку на участие, а также ознакомиться с подробной
						информацией о программе, в том числе с порядком проведения конкурсного отбора, этапами программы
						и сроками их проведения можно на портале программы <a
						href="https://защитник47.рф/z"
						target='_blank'
						className='underline font-bold hover:no-underline'
						rel="noreferrer"
					>защитник47.рф/z</a>.
					</div>
					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-2">
							<div className="font-bold text-lg">
								Доступная среда
							</div>
							<div className="leading-6">
								Адрес предоставления услуг: Россия, г. Санкт-Петербург, ул. Ждановская, д. 43, литер
								А.,<br/>
								Режим работы: ПН -- ПТ: c <span className='font-bold'>9:00</span> до <span
								className='font-bold'>15:30</span><br/>
								Обед: с <span className='font-bold'>13:00</span> ло <span
								className='font-bold'>13:30</span><br/>
								СБ -- ВС: <span className='font-bold'>выходной</span><br/>
								Телефон для связи: <a className='font-bold hover:underline' href="tel:+78122523124">+7
								(812)
								252-31-24</a>
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<div className="font-bold text-lg">
								Способы предоставления услуг для различной категории инвалидов и маломобильных граждан
							</div>
							<div className="leading-6">
								• условия для беспрепятственного доступа к объекту и предоставляемым услугам; <br/>
								• сопровождение инвалидов и иных маломобильных граждан, а также оказание им помощи на
								объекте; <br/>
								• в исключительных случаях -- выезд с месту проживания (нахождения) лица, относящегося к
								вышеуказанным категориям.
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<div className="flex justify-center items-center">
							<video
								className="lg:w-2/3 w-full hidden md:flex"
								controls="controls"
								src="/static/video/finance.mp4"
							/>
							<video
								className="w-full md:hidden flex"
								controls="controls"
								src="/static/video/finance-mobile.mp4"
							/>
						</div>
						<div className="leading-6 text-red-600">
							Мошенники выдают себя за сотрудников правоохранительных органов, банков, социальных служб,
							постоянно обновляют свои схемы и легенды.
							Если вам позвонил незнакомец, заговорил про деньги, требует от вас немедленных действий — не
							паникуйте. Прервите разговор. А если звонок все-таки встревожил, позвоните в банк и
							убедитесь, что все в порядке.
						</div>
						<div className="flex flex-col lg:flex-row gap-4 justify-between items-center w-full">
							<figure>
								<figcaption className="font-bold">Запись звонка - Соцфонд:</figcaption>
								<audio controls src="/static/audio/socfond.mp3"/>
							</figure>
							<figure>
								<figcaption className="font-bold">Запись звонка - Банк России 1:</figcaption>
								<audio controls src="/static/audio/bank-russia-1.mp3"/>
							</figure>
							<figure>
								<figcaption className="font-bold">Запись звонка - Банк России 2:</figcaption>
								<audio controls src="/static/audio/bank-russia-2.mp3"/>
							</figure>
						</div>

					</div>
					<div className="px-4 md:px-12 lg:px-48 flex justify-center items-center">
						<img
							src='/static/img/antiterror.webp'
							alt='antiterror-img'
							width={ 600 }
						/>
					</div>
					<div className="leading-6">
						Размещена процедура проведения электронного аукциона на право заключения договора аренды
						государственного
						имущества Ленинградской области, закрепленного на праве оперативного управления за
						Государственным казенным
						учреждением здравоохранения Ленинградской области Бюро судебно-медицинской экспертизы помещение
						№19
						(прощальный зал) площадью 25,5 кв.м, в здании хозблока (морга), расположенного по адресу:
						Российская Федерация, Ленинградская область, Всеволожский район, г.п. Токсово, ул. Буланова,
						д.18.
						Документация размещена на официальном сайте ГИС Торги <a className='font-bold hover:underline'
						                                                         href="https://torgi.gov.ru/">torgi.gov.ru</a>
					</div>
					<div className="leading-6 text-blue-600">
						<a className='font-bold hover:underline' href="tel:+78126795547">+7 (812) 679-55-47</a> -
						Колл-центр
						ГАУ
						ЛО «Ленфарм»<br/>
						Информирование льготных категорий граждан о наличии лекарственных препаратов (выдаваемых
						бесплатно)
						в
						аптеках Ленинградской области (пн-пт 9:00 — 17:00)
					</div>
					<div className="flex flex-col gap-4">
						<div className="px-4 md:px-12 lg:px-48 flex justify-center items-center">
							<a
								className="hover:brightness-90"
								href='https://xn--47-6kc1agaow8b3d.xn--p1ai/'
								target="_blank"
								rel="noreferrer"
							>
								<img
									src='/static/img/events/contract.jpg'
									alt='contract-img'
									width={ 1000 }
								/>
							</a>
						</div>
						<div className="leading-6">
							ГКУЗ ЛО БСМЭ информирует вас, что в Ленинградской области заработал короткий номер телефона
							для
							приема
							обращений граждан, желающих заключить контракт на службу в Вооруженных силах Российской
							Федерации.
							Звонки по номеру <a className='font-bold hover:underline' href="tel:147">147</a> принимаются
							ежедневно с
							9:00 до 21:00.
						</div>
					</div>
				</div>

			</div>

		</div>
	);

}

export default MainPage;

export const MainPageBlockWithBackgroundImage = styled.div`
	background: url("https://atomic-coon.ru/wp-content/uploads/2022/10/DSC_2800-800.jpg") no-repeat center 30% / cover;
	
	width: 100vw;
	height: 450px;
	padding: 270px 4rem 0 4rem;
	
	display: flex;
	flex-direction: column;
	
	@media(max-width: 767px){
		padding: 120px 1rem 1rem 1rem;
		gap: 1rem;
		height: 346px;
	}
`;
import { BackgroundBlock } from "../../components/ui";
import styled from "styled-components";

function StructurePage() {
	const title = "Структура бюро";

	return (
		<div className="flex flex-col gap-4 md:gap-8 ">
			<BackgroundBlock title={ title }/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4 md:gap-8 justify-center items-center`}>
				<div className="text-xl">Для увеличения нажмите на схему</div>
				<StructureScheme src='/static/html/structure.html'/>
			</div>
		</div>
	);
}

export default StructurePage;

const StructureScheme = styled.iframe`
	height: 100vh;
	width: 100%;
	
	@media(max-width: 1023px){
		height: 72vh;
	}
	
	@media(max-width: 767px){
		height: 36vh;
	}
`;
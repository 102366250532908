import { ContactPhone } from "./header";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const footerMenu = [
	{
		key: "footer-menu-item-events",
		displayName: "Мероприятия",
		href: "/meropriyatiya"
	},
	{
		key: "footer-menu-item-news",
		displayName: "Новости",
		href: "/news"
	},
	{
		key: "footer-menu-item-structure",
		displayName: "Структура Бюро",
		href: "/struktura"
	},
	{
		key: "footer-menu-item-doctors",
		displayName: "Информация о врачах",
		href: "/vrachi"
	},
	{
		key: "footer-menu-item-profsoyuz",
		displayName: "Контролирующие органы",
		href: "/kontroliruyushie-organi"
	},
	{
		key: "footer-menu-item-vakansii",
		displayName: "Вакансии",
		href: "/vakansii"
	},
	{
		key: "footer-menu-item-contacts",
		displayName: "Контакты",
		href: "/kontakti",
	},
	{
		key: "footer-menu-item-connect",
		displayName: "Обратная связь",
		href: "/obratnaya-svyaz",
	},
	{
		key: "footer-menu-item-sitemap",
		displayName: "Карта сайта",
		href: "/static/html/sitemap.html",
	},
];

function Footer() {
	const navigate = useNavigate();
	return (
		<TransparentFooterBlock
			className={ `p-4 md:py-12 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex justify-between items-center mt-8 md:mt-16 gap-4` }>
			<div className="hidden md:flex flex-col gap-2">
				{
					footerMenu.map(el => {
							return (

								el.href.startsWith("/static")
									?
									<a href={ el.href } key={ el.key } target="_blank" rel="noreferrer">
										<FooterMenuItem className="flex-1 text-sm">{ el.displayName }</FooterMenuItem>
									</a>
									: <FooterMenuItem className="flex-1 text-sm" key={ el.key }
									                  onClick={ () => navigate(el.href) }>{ el.displayName }</FooterMenuItem>
							);
						}
					) }
			</div>
			<div className="flex flex-col gap-4">
				<FooterText>
					Санкт-Петербург, ул. Шкапина, д. 36-38-40 литер «Б»
				</FooterText>
				<FooterText>
					Режим работы администрации:<br/>пн-пт 9:00 - 17:30
				</FooterText>
				<div className="flex flex-col flex-1 justify-center gap-2">
					<ContactPhone href="tel:+78122523124">
						<img src="/static/svg/phone.svg" alt="phone" width={ 20 }/>
						<FooterText>+7 (812) 252-31-24</FooterText>
					</ContactPhone>
					<ContactPhone href="tel:+78122524933">
						<img src="/static/svg/fax.svg" alt="phone" width={ 20 }/>
						<FooterText>+7 (812) 252-49-33</FooterText>
					</ContactPhone>
				</div>
				<ContactPhone href="mailto:info@lo-bsme.ru">
					<img src="/static/svg/email.svg" alt="email" width={ 20 }/>
					<FooterText>info@lo-bsme.ru</FooterText>
				</ContactPhone>
			</div>
			<ScrollUpBtn onClick={ () => window.scrollTo({ top: 0, behavior: 'smooth' }) }>
				<img src="/static/svg/arrow-up.svg" alt="scroll-up" width={ 80 }/>
			</ScrollUpBtn>

		</TransparentFooterBlock>
	);
}

export default Footer;

export const ScrollUpBtn = styled.button`
    &:hover{
        filter: brightness(66%);
    }
    height: 80px;
`;

export const FooterMenuItem = styled.div`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: start;
     color: #f3f3f3;
    
    &:hover{
         filter: brightness(85%);
    }
`;

export const FooterText = styled.div`
	 color: #f3f3f3;
`;

export const TransparentFooterBlock = styled.div`
    background-color: #3f3f3f;
`;
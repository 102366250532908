import { BackgroundBlock } from "../../components/ui";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";

function NewsUniversalPage(props) {
	const {
		date,
		preview,
		title,
		body,
		images,
		link
	} = props;

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock
				title={ "Новости" }
				links={
					[
						{
							href: "/news",
							name: "Новости"
						}
					]
				}
				noLastChild={ true }
			/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }>

				<div className="font-bold text-xl">
					{ title }
				</div>
				<div className="font-bold text-sm">
					{ date }
				</div>
				<div className="leading-6">
					{ body || '' }
				</div>
				<div className="flex justify-center items-center w-full">
					{
						images && images.length > 1
							? (
								<div>
									<div className="md:hidden flex">
										<ImageGallery
											items={
												images.map(image => {
													return {
														original: image,
														thumbnail: image
													}
												})
											}
											showPlayButton={ false }
											showThumbnails={ false }
											useBrowserFullscreen={ false }
										/>
									</div>
									<div className="md:flex hidden">
										<ImageGallery
											items={
												images.map(image => {
													return {
														original: image,
														thumbnail: image,
													}
												})
											}
											thumbnailPosition="right"
											showPlayButton={ false }
											useBrowserFullscreen={ false }
										/>
									</div>
								</div>
							)
							: (
								<img
									className={ "md:w-2/3 lg:w-1/2 w-full" }
									src={ preview }
									alt={ link }
								/>
							)
					}
				</div>

			</div>
		</div>
	);
}

export default NewsUniversalPage;
import { BackgroundBlock } from "../../components/ui";

function ExamplesPage(){
	const title = "Образцы запросов";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title } />
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<a
					className="leading-5 font-bold underline hover:no-underline md:text-justify"
					href="/static/pdf/documents/examples/example-sme.pdf"
					target="_blank"
				>
					Запрос на выдачу копии заключения эксперта / акта судебно-медицинского исследования
				</a>
				<a
					className="leading-5 font-bold underline hover:no-underline md:text-justify"
					href="/static/pdf/documents/examples/example-pao.pdf"
					target="_blank"
				>
					Запрос на выдачу копии протокола патологоанатомического вскрытия
				</a>
			</div>
		</div>
	);
}

export default ExamplesPage;
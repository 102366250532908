import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function Breadcrumbs(props) {
	const {
		links,
		lastChild,
		noLastChild
	} = props;

	const navigate = useNavigate();

	return (
		<div className="flex flex-col md:flex-row gap-1 md:mt-8">
			<LightText onClick={ () => navigate("/") } className="underline hover:no-underline text-xs cursor-pointer">
				Главная
			</LightText>
			{
				links && links.length
					? links.map(el => {
						const { name, href } = el;
						return (
							<div className="flex gap-1" key={ `breadcrumb-${ links.indexOf(el) }` }>
								<LightText className="text-xs">/</LightText>
								<LightText onClick={ () => navigate(href) }
								           className="underline hover:no-underline text-xs cursor-pointer">
									{ name }
								</LightText>
							</div>

						);
					})
					: <></>
			}
			{
				!noLastChild &&
				<div className="flex gap-1">
					<LightText className="text-xs">/</LightText>
					<LightText className="text-xs">{ lastChild }</LightText>
				</div>
			}
		</div>
	);
}

export default Breadcrumbs;

export const LightText = styled.span`
	color: #f3f3f3;
`;
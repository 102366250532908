import { BackgroundBlock } from "../../components/ui";

function AntiCorruptionPage(){
	const title = "Документы по противодействию коррупции";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title } />
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<a
					className="leading-5 font-bold underline hover:no-underline md:text-justify"
					href="/static/pdf/documents/anti-corruption/plan.pdf"
					target="_blank"
				>
					План противодействия коррупции 2025-2028
				</a>
				<a
					className="leading-5 font-bold underline hover:no-underline md:text-justify"
					href="/static/pdf/documents/anti-corruption/code.pdf"
					target="_blank"
				>
					Кодекс этики и должностного поведения
				</a>
				<a
					className="leading-5 font-bold underline hover:no-underline md:text-justify"
					href="/static/pdf/documents/anti-corruption/regulation.pdf"
					target="_blank"
				>
					Положение об аниткоррупционной политике
				</a>
			</div>
		</div>
	);
}

export default AntiCorruptionPage;
export const events = [
	{
		date: "07.03.2025",
		title: "Образовательная платформа «Зеленая школа» — видеоуроки, задания и тексты о системе сбора отходов в России, сортировке и переработке, опасных отходах, мировом опыте в сфере ТКО и экологической ответственности человека",
		link: "https://school.reo.ru/"
	},
	{
		date: "07.03.2025",
		title: "Образовательная платформа «Зеленый курс» - видеокурс на тему ТКО для работников отрасли, госслужащих и студентов профильных программ вузов",
		link: "https://reo.ru/green-course"
	},
	{
		date: "07.03.2025",
		title: "Интерактивный курс «Дневник эковолонтера» из 12 уроков для желающих погрузиться в тему добровольчества",
		link: "https://reo.ru/dobro"
	},
	{
		date: "07.03.2025",
		title: "Мультфильмы Всероссийского образовательного онлайн-проекта «Помощники Земли»",
		link: "https://reo.ru/pomoshchniki_zemli"
	},
	{
		date: "19.02.2025",
		title: "Здоровое поколение - иммунитет",
		link: "https://disk.yandex.ru/d/DEHYqiESreksjg/%D0%A0%D0%BE%D0%BB%D0%B8%D0%BA_%D0%98%D0%BC%D0%BC%D1%83%D0%BD%D0%B8%D1%82%D0%B5%D1%82.mp4"
	},
	{
		date: "13.02.2025",
		title: "Информационное письмо по резонансным преступлениям с участием граждан, отнесенных к категории социально незащищенных (пенсионеры)",
		link: "/static/pdf/events/13.02.25.pdf"
	},
	{
		date: "27.12.2024",
		title: "Как не стать жертвой мошенников и не совершить террористический акт",
		link: "/static/pdf/events/27.12.24.pdf"
	},
	{
		date: "25.12.2024",
		title: "Телекоммуникационная безопасность",
		link: "https://kpb.lenobl.ru/ru/o-komitete/telekommunikacionnaya-bezopasnost/"
	},
	{
		date: "25.12.2024",
		title: "Памятка о недопущении жестокого обращения с пожилыми людьми",
		link: "https://zdrav.lenobl.ru/ru/news/78591/"
	},
	{
		date: "24.12.2024",
		title: "Методические материалы по формированию планов противодействия коррупции в органах исполнительной власти Ленинградской области",
		link: "/static/pdf/events/anticorruption02.pdf"
	},
	{
		date: "13.12.2024",
		title: "Популяризация здорового питания",
		link: "https://disk.yandex.ru/d/sF6VJ5axzNNj5w/%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D0%BE%D0%B5%20%D0%BF%D0%BE%D0%BA%D0%BE%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5_%D0%90%D1%81%D0%B8%D1%8F%D1%82%20%D0%A5%D0%B0%D1%87%D0%B8%D1%80%D0%BE%D0%B2%D0%B0.mov"
	},
	{
		title: "Протокол совещания с субъектами Российской Федерации по вопросу развития и продвижения официального портала Минздрава России о здоровье",
		link: "/static/pdf/events/19.09.24.pdf"
	},
	{
		title: "Меры по предупреждению коррупции в государственных учреждениях и организациях Ленинградской области",
		link: "/static/pdf/events/anticorruption1.pdf"
	},
	{
		title: "Методические основы по организации работы по предупреждению коррупции в государственных организациях",
		link: "/static/pdf/events/anticorruption0.pdf"
	},
	{
		title: "Памятка по созданию контента для антинаркотической политики",
		link: "/static/pdf/events/24.05.24.pdf"
	},
	{
		title: "Год семьи 2024",
		link: "https://xn--2024-u4d6b7a9f1a.xn--p1ai/"
	},
	{
		title: "Профилактические медицинские осмотры и диспансеризация",
		link: "https://org.gnicpm.ru/profilakticheskie-mediczinskie-osmotry-i-dispanserizacziya-s-subtitrami"
	}
];